import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

// for full detailing and practicing of emailjs visit on https://www.youtube.com/watch?v=t1_kviNJsy4


const Join = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm('service_zgh30fc', 'template_h0ektmt', form.current, 'qK2ISVjbD6EHFN9_g')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className="Join" id='join-us'>
        <div className="left-j">
            <hr />
            <div>
                <span className="stroke-text">READY TO</span>
                <span> LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className="stroke-text"> WITH US?</span>
            </div>
        </div>

        <div className="right-j">
          {/* ref isme bhi dena hai */}
          <form ref={form} onSubmit={sendEmail} action="" className="email-container">
            {/* input me jo name da hai use emailjs ke template ke name me same dena hai bs ye yaad rkho... */}
            <input type="email" name='user_email' placeholder='Enter Your Email.. ' />
            {/* <button className="btn btn-j" onClick='submit'>Join Now</button> */}
            <input type="submit" value="Send" className='btn btn-j' style={{backgroundColor: 'white'}}/>
          </form>
        </div>
    </div>
  )
}

export default Join