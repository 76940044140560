import React, { useState } from 'react'
import './Testimonials.css'
import {testimonialsData} from '../../data/testimonialsData'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'

import {motion} from 'framer-motion'

const Testimonials = () => {

    const transition = {type:'spring' , duration:2}

    const [selected,setselected] = useState(0);
    const tlength = testimonialsData.length;
  return (
    <div className="Testimonials">
        <div className="left-t">
            <span>Testimonials</span>
            <span className="stroke-text">What they</span>
            <span>say about us</span>
            <motion.span initial={{opacity:0,x:-100}} key={selected} animate={{opacity:1,x:0}} exit={{opacity:0,x:100}} transition={transition} >
                {testimonialsData[selected].review}
            </motion.span>
            <span>
                <span style={{color:'var(--orange)'}}>{testimonialsData[selected].name}</span>
                - {testimonialsData[selected].status}
            </span>
        </div>
        <div className="right-t">
            <motion.div initial={{opacity:0,x:-100}} whileInView={{opacity:1,x:0}} transition={{...transition,duration:2}}></motion.div>
            <motion.div initial={{opacity:0,x:100}} whileInView={{opacity:1,x:0}} transition={{...transition,duration:2}}></motion.div>
            {/*key is used to detect whether img should be animated or not coz every time the key is unique for every render  */}
            <motion.img initial={{opacity:0,x:100}} key={selected} animate={{opacity:1,x:0}} exit={{opacity:0,x:-100}} transition={transition}  src={testimonialsData[selected].image} alt="" />


            <div className="arrows">
                <img src={leftArrow} alt=""  onClick={()=>{
                selected===0 ? setselected(tlength-1) : setselected((prev)=>prev-1)
            }} />
                <img src={rightArrow} alt=""   onClick={()=>{
                selected===tlength-1 ? setselected(0) : setselected((prev)=>prev+1)
            }}/>
            </div>
        </div>
    </div>
  )
}

export default Testimonials