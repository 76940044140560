// import React from 'react'
// import './Header.css'
// import logo from '../../assets/logo.png'
// const Header = () => {
//   return (
//     <div className='header'>
//         {/* img bhin import krani pdegi for usage... */}
//         <img src={logo} alt="" className="logo" />       

//         <ul className='header-menu'>
//             <li>home</li>
//             <li>programs</li>
//             <li>why us</li>
//             <li>plans</li>
//             <li>testimonials</li>
//         </ul>                      
//     </div>
//   )
// }

// export default Header



// ye responsive krne pr hai code upr wala without uske
import React, { useState } from 'react'
import './Header.css'
import logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'
const Header = () => {

  const mobile = window.innerWidth<=768 ? true : false
  const [menuopened,setmenuopened] = useState(false)

  return (
    <div className='header'>
        {/* img bhin import krani pdegi for usage... */}
        <img src={logo} alt="" className="logo" />       

        {menuopened===false && mobile===true ? (
          <div style={{
            backgroundColor: 'var(--appColor)',
            padding:'0.5rem',
            borderRadius:'5px'
          }}>
            <img src={Bars} alt="" style={{width:'1.5rem',height:'1.5rem'}} onClick={()=>setmenuopened(true)}/>
          </div>
        ) : (
          <ul className='header-menu'>
            <li onClick={()=>setmenuopened(false)}>
              <Link to='home' smooth={true} span={true}>Home</Link>
            </li>
            <li onClick={()=>setmenuopened(false)}>programs</li>
            <li onClick={()=>setmenuopened(false)}>why us</li>
            <li onClick={()=>setmenuopened(false)}>plans</li>
            <li onClick={()=>setmenuopened(false)}>
              <Link to='Testimonials' span={true} smooth={true}>Testimonials</Link>
            </li>
        </ul>
        )}

                              
    </div>
  )
}

export default Header