import React from 'react'
import Header from '../Header/Header'
import './Hero.css'                                   //styling krne ke liye uss folder ko import krna bhut important hai...

import hero_img from '../../assets/hero_image.png'
import hero_img_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'

// for animation:   import framer motion and const transition bnao and fr motion.div bnao and fer motion.div me initial mtlb kha se shuru hoga or whileinview mtlb kaha tk laana hai and fer transition se iski speed control me leke aaye hai...
import {motion} from 'framer-motion'

// number counter
import NumberCounter from 'number-counter'
const Hero = () => {
  const transition = {type:'spring' , duration:2}
  // for responsive
  const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className="hero" id='home'>

      <div className="blur hero-blur"></div>
      <div className="left-h">

        <Header/>                               {/*header me humne sab kaam kiya hai.. */}

        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div initial={{left: mobile? '220px' : '300px'}} whileInView = {{left: '8px'}} transition= {{...transition,type:'tween'}} >
          </motion.div>
          <span>the best fitness club in the town</span>
        </div>

        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>shape </span>
            <span>your</span>
          </div>


          <div>
            <span>ideal body</span>
          </div>

          <div>
            <span>in here we will help you to shape and build your ideal body to live up your life to the fullest</span>
          </div>
        </div>


        {/* figures */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter start={100} end={140} prefix='+' delay='3'/>
            </span>
            <span>expert coachs</span>
          </div>
          <div>
            <span>
            <NumberCounter start={850} end={978} prefix='+' delay='1'/>
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
            <NumberCounter start={0} end={50} prefix='+' delay='1'/>
            </span>
            <span>fitness programs</span>
          </div>
        </div>





        {/* hero buttons */}
      <div className="hero-buttons">
        <div className="btn">get started</div>
        <div className="btn">learn more</div>
      </div>


      
      </div>



      


      <div className="right-h">

        <button className="btn">join now</button>

        <motion.div className="heart-rate" initial={{right:'-1rem'}} whileInView={{right:'4rem'}} transition={transition}>
          <img src={heart} alt="" />
          <span>heart rate</span>
          <span>116 bpm</span>
        </motion.div>

        {/* *******************hero images****************** */}
        <img src={hero_img} alt="" className='hero-image' />
        <motion.img src={hero_img_back} initial={{right:'18rem'}} whileInView={{right:'24rem'}} transition={transition} alt="" className="hero-image-back" />



        {/* **********************calories************ */}
        <motion.div className="calories" initial={{right:'45rem'}} whileInView={{right:'31rem'}} transition={transition}>
          <img src={calories} alt="" />
          <div>
            <span>calories burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Hero